



































































































































import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { inventoryLineBatchService } from "@service/inventory-line-batch.service";
import { ResponseInventoryLineBatch, ResponseListInventoryLineBatch } from "@interface/inventory-line-batch.interface";
import Vue from "vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponsePagination } from "@/models/interface/common.interface";

interface Row extends ResponseInventoryLineBatch {
  key: any;
}

export default Vue.extend({
  name: "CSelectTableLocationBatch",
  mixins: [
    MNotificationVue,
  ],
  props: {
    propProductCode: {
      type: String,
      default: undefined,
    },
    propSelectedBatch: {
      type: Array as () => Row[],
      default: undefined,
    },
  },
  data: () => ({
    dtSource: [] as Row[],
    loading: false,
    queryParams: {
      page: 0,
      limit: 20,
      search: "available>0",
      sorts: "batch.packDate:asc",
    },
    dtInventory: {} as ResponseListInventoryLineBatch,
    selectedBatch: [] as Row[],
  }),
  created() {
    if (this.propProductCode) {
      this.queryParams.search = `product.code~${this.propProductCode}_AND_available>0`;
    }
    this.getList(this.queryParams);
    this.prefill();
  },
  methods: {
    getListInventoryLinesBatch(params: RequestQueryParamsModel): Promise<ResponseListInventoryLineBatch> {
      return inventoryLineBatchService.getListInventoryLineBatch(params);
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListInventoryLinesBatch(params);
        this.dtSource = res.data.map((x, i) => ({
          key: i,
          ...x
        }));
        this.dtInventory = res;
      } catch(error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    selectLocation(record: Row): void {
      // this.$emit("on-select", { value: record.warehouseLocationId, meta: record });
      const batch = this.selectedBatch.find(x => x.batchId === record.batchId);
      if (batch) {
        this.showNotifError("notif_duplicate_item", { data: batch.batchNumber });
      } else {
        this.selectedBatch.push(record);
        // this.$emit("on-select", { value: record.warehouseLocationId, meta: record });
      }
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getList(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.getList(this.queryParams);
    },
    deleteBatch(r: Row): void {
      const { selectedBatch } = this;
      this.selectedBatch = selectedBatch.filter(x => x.id !== r.id);
    },
    handleSave(): void {
      this.$emit("on-save", { value: this.selectedBatch });
    },
    handleClose(): void {
      this.$emit("on-close");
    },
    prefill(): void {
      this.selectedBatch = this.propSelectedBatch ?? [];
    },
  }
});
