var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    loading: _vm.loading,
                    scroll: { y: 520 },
                    pagination: false
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "batchNumber",
                      attrs: { "data-index": "batchNumber", width: 280 },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " + _vm._s(record.batchNumber || "-") + " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_batch_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "warehouseLocationName",
                      attrs: { "data-index": "warehouseLocationName" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(record.warehouseLocationName || "-") +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_rack")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "available",
                      attrs: { "data-index": "available" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(" " + _vm._s(record.available || 0) + " ")
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_qty_available")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "packDate",
                      attrs: { "data-index": "packDate" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(record.packDate)) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_pack_date")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "actions",
                      attrs: { align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: { icon: "check", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectLocation(record)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_choose")) + " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_action")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            _vm._l(_vm.selectedBatch, function(item) {
              return _c(
                "a-button",
                {
                  key: item.id,
                  staticClass: "mr-2",
                  attrs: { type: "dashed" },
                  on: {
                    click: function($event) {
                      return _vm.deleteBatch(item)
                    }
                  }
                },
                [
                  _c("a-icon", {
                    staticClass: "icon-delete",
                    attrs: { type: "delete", theme: "filled" }
                  }),
                  _vm._v(" " + _vm._s(item.batchNumber || "-") + " ")
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [16, 16],
            type: "flex",
            justify: "space-between",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dtInventory.totalElements,
                  "page-size-set": _vm.queryParams.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { align: "end" } },
            [
              _c("a-tag", { attrs: { color: "grey" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dtInventory.totalElements) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClose } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", icon: "save" },
                      on: { click: _vm.handleSave }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }